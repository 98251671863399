<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-pagination
    item-key="_id"
  >
    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <plan-status-template :status="item.status" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.planid="{ item }">
      <plan-id-template :planid="item.planid" :trial="item.trial" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.actualstart="{ item }">
      <span v-if="item.actualstart">
        <plan-start-template :datetime="item.actualstart" />
      </span>
      <span v-else>
        <plan-start-template :datetime="item.scheduledstart" />
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.action="{ item }">
      <plan-actions-template :plan="item" />
    </template>
  </v-data-table>
</template>

<script>
import PlanStatusTemplate from './PlanStatusTemplate.vue';
import PlanIdTemplate from './PlanIdTemplate.vue';
import PlanStartTemplate from './PlanStartTemplate.vue';
import PlanActionsTemplate from './PlanActionsTemplate.vue';

export default {
  name: 'StatusView',
  components: {
    PlanStatusTemplate,
    PlanIdTemplate,
    PlanStartTemplate,
    PlanActionsTemplate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '', value: 'status' },
        { text: this.$t('assemblyPlanningDover.plan'), value: 'planid' },
        {
          text: this.$t('assemblyPlanningDover.part'),
          value: 'partname',
          width: '10%',
        },
        {
          text: this.$t('assemblyPlanningDover.model'),
          value: 'model',
          width: '10%',
        },
        {
          text: this.$t('assemblyPlanningDover.variant'),
          value: 'variant',
          width: '10%',
        },
        {
          text: this.$t('assemblyPlanningDover.customer'),
          value: 'customer',
          width: '10%',
        },
        {
          text: this.$t('assemblyPlanningDover.tenderNumber'),
          value: 'tendernumber',
          width: '10%',
        },
        {
          text: this.$t('assemblyPlanningDover.dispatchLocation'),
          value: 'dispatchlocation',
          width: '10%',
        },
        { text: this.$t('assemblyPlanningDover.line'), value: 'linename' },
        { text: this.$t('assemblyPlanningDover.plannedQty'), value: 'plannedquantity' },
        { text: this.$t('assemblyPlanningDover.smh'), value: 'smh' },
        {
          text: this.$t('assemblyPlanningDover.action'),
          value: 'action',
          sortable: false,
          width: '10%',
        },
      ],
    };
  },
};
</script>
