<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndExit)">
      <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title>
            {{ $t('assemblyPlanningDover.cannotEditTitle') }}
          </v-card-title>
          <v-card-text>
            {{ $t('assemblyPlanningDover.cannotEditSubtitle') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-none"
              color="primary"
              @click="exit"
            >
              {{ $t('assemblyPlanningDover.goBack') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.part')"
                      :items="partList"
                      item-text="partname"
                      item-value="partname"
                      v-model="selectedPart"
                      @change="fetchPartByPartName"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.model')"
                      :items="modelList"
                      :disabled="saving || !selectedPart"
                      :loading="fetchingMatrix"
                      item-text="model"
                      item-value="model"
                      v-model="selectedModel"
                      @change="filterVariant"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.variant')"
                      :items="variantList"
                      :disabled="saving || !selectedModel"
                      :loading="fetchingMatrix"
                      item-text="variant"
                      item-value="variant"
                      v-model="selectedVariant"
                      @change="filterCustomer"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.customer')"
                      :items="customerList"
                      item-text="customer"
                      item-value="customer"
                      :disabled="saving || !selectedVariant"
                      v-model="selectedCustomer"
                      @change="filterLine"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.line')"
                      :items="lineList"
                      item-text="linename"
                      item-value="linename"
                      :disabled="saving || !selectedCustomer"
                      v-model="selectedLine"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningDover.dispatchLocation')"
                      :items="locations"
                      item-text="dispatchlocation"
                      item-value="dispatchlocation"
                      :disabled="saving || !selectedLine"
                      v-model="selectedDispatchLocation"
                      @change="setPlanParameters"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      :label="$t('assemblyPlanningDover.tenderNumber')"
                      outlined
                      v-model="plan.tendernumber"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="title mb-2">
                  {{ $t('assemblyPlanningDover.operationalParams') }}
                  <v-btn
                    small
                    outlined
                    class="text-none"
                    :disabled="!selectedMatrix || saving"
                    @click="editParams = !editParams"
                    color="primary"
                  >
                    <template v-if="!editParams">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      {{ $t('assemblyPlanningDover.editButton') }}
                    </template>
                    <template v-else>
                      <v-icon left small>mdi-check</v-icon>
                      {{ $t('assemblyPlanningDover.doneButton') }}
                    </template>
                  </v-btn>
                </div>
                <v-row v-for="macdetails in plan.machinedetails" :key="macdetails.machinename">
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningDover.machine')"
                        type="name"
                        disabled
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        v-model="macdetails.machinename"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="cycletime"
                      rules="required|min_value:1"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningDover.cycletime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        :suffix="$t('assemblyPlanningDover.secs')"
                        v-model="macdetails.stdcycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="delaytime"
                      rules="required|min_value:0"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningDover.delayTime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cached"
                        outlined
                        :suffix="$t('assemblyPlanningDover.secs')"
                        v-model="macdetails.delaytime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="title mt-4 mb-2">
                  {{ $t('assemblyPlanningDover.planDetails') }}
                </div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="quantity"
                      :rules="`required|numeric|min_value:1|multiple_of:${plan.activecavity}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningDover.quantity')"
                        type="number"
                        :disabled="saving || inProgress"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-package-variant-closed"
                        outlined
                        :suffix="$t('assemblyPlanningDover.pieces')"
                        v-model="plan.plannedquantity"
                        hide-details="auto"
                        @change="onQuantityChange"
                        :hint="`Should be multiple of active cavity(${plan.activecavity})`"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      :rules="`${notStarted
                        ? 'required|greater_than_now'
                        : 'required'}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningDover.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="plan.scheduledstart"
                        hide-details="auto"
                        :disabled="saving || inProgress"
                        @change="fetchEstimatedEnd"
                      >
                        <template #append-outer>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-text="'$info'"
                                v-on="on"
                                v-bind="attrs"
                              ></v-icon>
                            </template>
                            <ul>
                              <li>
                                Scheduled start time is only used in calculating
                                planned vs actual performance.
                              </li>
                              <li>
                                Updating scheduled start time will not affect
                                the execution order of the plan.
                              </li>
                              <li>
                                Plans are picked automatically in FIFO fashion.
                              </li>
                              <li>
                                To update the execution order, please visit
                                <strong>re-order plans section</strong>.
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningDover.markAsStar')"
                      :disabled="saving"
                      v-model="plan.starred"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningDover.markAsTrial')"
                      :disabled="saving || inProgress"
                      v-model="plan.trial"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  {{ $t('assemblyPlanningDover.estimatedEnd') }}
                  <strong>{{ estimatedEndDisplay }}</strong>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('assemblyPlanningDover.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="text-none"
                  :loading="saving || !selectedPart || !plan.plannedquantity
                    || !plan.tendernumber"
                  type="submit"
                >
                  {{ $t('assemblyPlanningDover.saveExit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
// import { sortArray } from '@shopworx/services/util/sort.service';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'EditPlan',
  data() {
    return {
      dialog: false,
      headers: [
        { text: this.$t('assemblyPlanningDover.part'), value: 'partname' },
        { text: this.$t('assemblyPlanningDover.activeCavity'), value: 'activecavity' },
        { text: this.$t('assemblyPlanningDover.quantity'), value: 'plannedquantity' },
      ],
      editParams: false,
      saving: false,
      fetchingMatrix: false,
      variantList: [],
      customerList: [],
      lineList: [],
      modellist: [],
      partMatrixList: [],
      selectedModel: null,
      selectedVariant: null,
      selectedCustomer: null,
      selectedLine: null,
      selectedPart: null,
      selectedDispatchLocation: null,
      plan: {},
    };
  },
  computed: {
    ...mapState('assemblyPlanningDover', [
      'parts',
      'partMatrix',
      'selectedPlan',
      'locations',
    ]),
    ...mapGetters('assemblyPlanningDover', ['selectedAsset', 'partMatrixTags']),
    ...mapGetters('helper', ['locale']),
    inProgress() {
      return this.selectedPlan[0].status === 'inProgress';
    },
    notStarted() {
      return this.selectedPlan[0].status === 'notStarted';
    },
    // complete() {
    //   return this.selectedPlan[0].status === 'complete';
    // },
    abort() {
      return this.selectedPlan[0].status === 'abort';
    },
    partList() {
      return this.parts;
    },
    customers() {
      return this.customerList;
    },
    estimatedEndDisplay() {
      let res = '';
      if (this.plan.scheduledend) {
        res = formatDate(
          new Date(this.plan.scheduledend),
          'PPpp',
          { locale: this.locale },
        );
      }
      return res;
    },
    selectedMatrix() {
      if (this.selectedPart && this.selectedLine) {
        const partMatrix = this.partMatrixList.filter((matrix) => (
          matrix.partname === this.selectedPart
          && matrix.linename === this.selectedLine
        ));
        return partMatrix.sort((a, b) => a.sequence - b.sequence);
      }
      return null;
    },
    shots() {
      if (this.plan.plannedquantity && this.plan.activecavity) {
        return +this.plan.plannedquantity / +this.plan.activecavity;
      }
      return 0;
    },
  },
  async created() {
    this.clear();
    await this.fetchLocations();
    this.setPlan();
    if (this.complete || this.abort) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('assemblyPlanningDover', [
      'fetchPartMatrix',
      // 'isFamilyMold',
      // 'getFamilyParts',
      'getScheduledEnd',
      'fetchLastPlan',
      'createPlans',
      'deletePlanById',
      'updatePlanById',
      'fetchModel',
      'fetchLocations',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        partname: '',
        linename: '',
        activecavity: 1,
        machinedetails: [],
        plannedquantity: 0,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        starred: false,
        trial: false,
        sortindex: 0,
        status: 'notStarted',
        // remark: '',
      };
    },
    async setPlan() {
      const [plan] = this.selectedPlan;
      this.selectedPart = this.partList
        .find((p) => p.partname === plan.partname).partname;
      this.fetchingMatrix = true;
      this.modelList = await this.fetchModel({ partname: this.selectedPart });
      this.selectedModel = this.modelList
        .find((p) => p.model === plan.model)
        .model;
      this.filterVariant();
      this.selectedVariant = this.modelList
        .find((p) => p.variant === plan.variant)
        .variant;
      this.filterCustomer();
      this.selectedCustomer = this.customerList
        .find((p) => p.customer === plan.customer)
        .customer;
      this.filterLine();
      this.selectedLine = this.variantList
        .find((m) => m.linename === plan.linename)
        .linename;
      this.selectedDispatchLocation = this.locations
        .find((m) => m.dispatchlocation === plan.dispatchlocation)
        .dispatchlocation;
      // this.filterPart();
      // this.selectedPart = this.variantList
      //   .find((p) => p.partname === plan.partname);
      this.fetchingMatrix = false;
      await this.setEditParams(plan);
    },
    async setEditParams(plan) {
      this.plan = {
        // eslint-disable-next-line
        assetid: plan.assetid,
        model: plan.model,
        variant: plan.variant,
        customer: plan.customer,
        partname: plan.partname,
        linename: plan.linename,
        dispatchlocation: plan.dispatchlocation,
        machinedetails: plan.machinedetails,
        activecavity: plan.activecavity,
        plannedquantity: plan.plannedquantity,
        scheduledstart: formatDate(new Date(plan.scheduledstart), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: formatDate(new Date(plan.scheduledend), 'yyyy-MM-dd\'T\'HH:mm'),
        starred: plan.starred,
        trial: plan.trial,
        sortindex: plan.sortindex,
        status: plan.status,
        tendernumber: plan.tendernumber,
      };
    },
    clear() {
      this.initPlan();
      this.editParams = false;
      this.variantList = [];
      this.customerList = [];
      this.lineList = [];
      this.modelList = [];
      this.selectedModel = null;
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      this.selectedDispatchLocation = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async fetchPartByPartName() {
      this.initPlan();
      this.variantList = [];
      this.customerList = [];
      this.lineList = [];
      this.modelList = [];
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedModel = null;
      if (this.selectedPart) {
        this.fetchingMatrix = true;
        this.modelList = await this.fetchModel({ partname: this.selectedPart });
        if (this.modelList && this.modelList.length) {
          this.selectedModel = this.modelList[0].model;
          this.filterVariant();
        }
        this.fetchingMatrix = false;
      }
    },
    filterVariant() {
      this.initPlan();
      this.variantList = [];
      this.lineList = [];
      this.customerList = [];
      this.selectedVariant = null;
      this.selectedLine = null;
      this.selectedCustomer = null;
      if (this.selectedModel) {
        this.variantList = this.modelList
          .filter((m) => m.model === this.selectedModel);
        if (this.variantList && this.variantList.length) {
          this.selectedVariant = this.variantList[0].variant;
          this.filterCustomer();
        }
      }
    },
    filterCustomer() {
      this.initPlan();
      this.lineList = [];
      this.selectedCustomer = null;
      this.selectedLine = null;
      if (this.selectedVariant) {
        this.customerList = this.modelList
          .filter((m) => m.model === this.selectedModel);
        if (this.customerList && this.customerList.length) {
          this.selectedCustomer = this.customerList[0].customer;
          this.filterLine();
        }
      }
    },
    filterLine() {
      this.initPlan();
      this.lineList = [];
      this.selectedLine = null;
      if (this.selectedCustomer) {
        this.lineList = this.customerList
          .filter((customer) => customer.customer === this.selectedCustomer);
        this.partMatrixList = this.lineList;
      }
    },
    // filterPart() {
    //   this.initPlan();
    //   this.partList = [];
    //   this.selectedPart = null;
    //   if (this.selectedLine) {
    //     this.partList = this.lineList
    //       .filter((line) => line.linename === this.selectedLine);
    //     this.partMatrixList = this.partList;
    //     [this.selectedPart] = this.partList;
    //     this.setPlanParameters();
    //   }
    // },
    async setPlanParameters() {
      this.initPlan();
      if (this.selectedPart && this.selectedLine) {
        this.plan.assetid = this.partList[0].assetid;
        this.plan.partname = this.selectedPart;
        this.plan.model = this.selectedModel;
        this.plan.variant = this.selectedVariant;
        this.plan.customer = this.selectedCustomer;
        this.plan.linename = this.selectedLine;
        this.plan.dispatchlocation = this.selectedDispatchLocation;
        this.plan.activecavity = 1;
        this.plan.machinedetails = [];
        this.selectedMatrix.forEach((part) => {
          const {
            machinename,
            stdcycletime,
            linename,
            delaytime,
          } = part;
          this.plan.machinedetails.push({
            machinename,
            stdcycletime,
            linename,
            delaytime,
          });
        });
      }
    },
    async fetchEstimatedEnd() {
      if (this.plan.plannedquantity && this.plan.scheduledstart) {
        const machineCount = this.plan.machinedetails.length;
        const totalStdCycletime = this.plan.machinedetails.reduce((acc, cur) => {
          acc += (+cur.stdcycletime * 1000);
          return acc;
        }, 0);
        this.plan.smh = +((totalStdCycletime / 3600000) * this.plan.plannedquantity).toFixed(2);
        let ctAvg = 0;
        if (machineCount !== 0) {
          ctAvg = Math.floor(totalStdCycletime / machineCount);
        }
        const runTime = ((+this.plan.plannedquantity - 1)
          * ctAvg) + totalStdCycletime;
        this.plan.linestdcycletime = (runTime / 1000);
        this.plan.scheduledend = await this.getScheduledEnd({
          start: new Date(this.plan.scheduledstart).getTime(),
          duration: runTime,
        });
      } else {
        this.plan.scheduledend = '';
      }
    },
    // onCavityChange() {
    //   if (this.familyParts && this.familyParts.length) {
    //     this.updateFamilyParams();
    //   }
    // },
    onQuantityChange() {
      this.fetchEstimatedEnd();
      // if (this.familyParts && this.familyParts.length) {
      //   this.updateFamilyParams();
      // }
    },
    // updateFamilyParams() {
    //   this.familyParts = this.familyParts.map((p) => ({
    //     ...p,
    //     plannedquantity: +p.activecavity * this.shots,
    //   }));
    // },
    // updateFamilyCavity({ id, payload }) {
    //   // eslint-disable-next-line
    //   const index = this.familyParts.findIndex((p) => p._id === id);
    //   this.$set(this.familyParts, index, {
    //     ...this.familyParts[index],
    //     plannedquantity: +payload * this.shots,
    //   });
    // },
    exit() {
      this.clear();
      this.selectedPart = null;
      this.dialog = false;
      this.$router.go(-1);
    },
    async delete() {
      const ids = [
        // eslint-disable-next-line
        this.selectedPlan[0]._id,
        // eslint-disable-next-line
        // ...this.familyToEdit.map((p) => p._id),
      ];
      const deleted = await Promise.all(ids.map((id) => this.deletePlanById(id)));
      if (deleted.every((d) => d)) {
        return true;
      }
      return false;
    },
    async save() {
      // const matrixTags = await this.partMatrixTags(this.plan.assetid);
      // const partMatrix = matrixTags.reduce((acc, cur) => {
      //   acc[cur.tagName] = this.selectedMatrix[cur.tagName];
      //   return acc;
      // }, {});
      const payload = [{
        // ...partMatrix,
        ...this.plan,
        scheduledstart: new Date(this.plan.scheduledstart).getTime(),
        scheduledend: new Date(this.plan.scheduledend).getTime(),
        // familyName: 'family',
      }];
      // eslint-disable-next-line
      // const ids = [...new Set(this.selectedFamilyParts.map((p) => p._id))];
      // eslint-disable-next-line
      // this.selectedFamilyParts = this.familyParts.filter((p) => ids.includes(p._id));
      // if (this.selectedFamilyParts && this.selectedFamilyParts.length) {
      //   const familyPayload = this.selectedFamilyParts.map((p) => ({
      //     ...payload[0],
      //     cavity: p.cavity,
      //     activecavity: p.activecavity,
      //     plannedquantity: p.plannedquantity,
      //     partname: p.partname,
      //   }));
      //   payload = [...payload, ...familyPayload];
      // }
      const created = await this.createPlans(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'PLAN_UPDATED',
        });
      }
      return created;
    },
    setEditPayload(payload) {
      const data = {
        plannedquantity: +payload.plannedquantity,
        machinedetails: payload.machinedetails,
        activecavity: +payload.activecavity,
        starred: this.plan.starred,
      };
      return data;
    },
    async update() {
      // let updatePayload = this.familyParts.map(({ id, ...p }) => ({
      //   id,
      //   payload: this.setEditPayload(p),
      // }));
      const updatePayload = [{
        // eslint-disable-next-line
        id: this.selectedPlan[0]._id,
        payload: this.setEditPayload(this.plan),
      }];
      const updated = await Promise.all(updatePayload.map((p) => this.updatePlanById(p)));
      if (updated.every((d) => d)) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        });
        return true;
      }
      this.setAlert({
        show: true,
        type: 'error',
        message: 'PLAN_UPDATED',
      });
      return false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.saving = true;
        if (this.inProgress) {
          const updated = await this.update();
          if (updated) {
            this.exit();
          }
        } else {
          const deleted = await this.delete();
          if (deleted) {
            const created = await this.save();
            if (created) {
              this.exit();
            }
          }
        }
        this.saving = false;
      }
    },
  },
};
</script>
