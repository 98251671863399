<template>
  <v-card
    flat
    height="100%"
    rounded="lg"
  >
    <v-card-title primary-title>
      {{ $t('assemblyPlanningDover.stagewiseProduction') }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <highcharts
        v-if="options"
        :options="options"
      ></highcharts>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'StageWiseProduction',
  data() {
    return {
      loading: false,
      options: null,
      reportName: 'assemblyplanwisemachinewiseproduction',
    };
  },
  async created() {
    this.loading = true;
    const reportData = await this.getReportData(this.reportName);
    this.options = reportData.chartOptions;
    this.options.chart.height = 350;
    this.loading = false;
  },
  methods: {
    ...mapActions('assemblyPlanningDover', ['getReportData']),
  },
};
</script>
